.gif {
  width: 100px;
  height: 100px;
  margin: auto;
}

.gif_button {
  border: none;
  padding: 0;
  cursor: pointer;
  margin-top: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

@media screen and (min-width: 400px) {
  .gif {
    width: 150px;
    height: 150px;
  }
}

@media screen and (min-width: 768px) {
  .gif_button:hover {
    -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
            transform: scale(1.1);
  }
  .gif {
    width: 200px;
    height: 200px;
  }

}

