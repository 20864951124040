.title{
    text-align: center;
}

.gif{
    width: 200px;
    height: 200px;
    margin: auto;
}

.gif img{
    width: 100%;
    height: 100%;
}

.skip_button{
    margin-bottom: 50px;
}