.main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
}

.searchbar {
  width: 300px;
  margin: 0 auto 20px;
  border-width: 0 0 1px;
  padding: 5px;
  text-align: center;
}

.searchbar:focus-visible {
    outline: none;
    background-color: rgb(221, 221, 221);
}

.searchbar:focus{
    outline: none;
    background-color: rgb(221, 221, 221);
}

.button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 20px;
}

.gif_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: auto;
  padding: 5px;
}

@media screen and (min-width: 1024px) {
  .gif_container {
    width: 650px;
  }
}
