.main{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 80%;
    margin: 50px auto 0px;
    text-align: center;
}

.focus_input{
    width:70%;
    border-width: 0px 0px 1px;
    padding: 5px;
    text-align: center;
}

.focus_input:focus-visible {
    outline: none;
    background-color: rgb(221, 221, 221);
}

.focus_input:focus{
    outline: none;
    background-color: rgb(221, 221, 221);
}

.note{
    margin-top:0px;
    font-weight: bold;
    font-size: 15px;
}
