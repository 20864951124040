.menu {
  margin: 0;
  width: 80%;
  position: absolute;
  top: 120px;
  z-index: 100;
  -webkit-transition: all .6s ease;
  -o-transition: all .6s ease;
  transition: all .6s ease;
  left: -90%;
  padding: 10px;
  border-radius: 0px 10px 10px 0px;
}

.menu > span {
  font-weight: 700;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 15px;
  display: block;
  cursor: pointer;
  margin: 10px 0px;
  padding: 0px 5px;
}

.menu.opened {
  left: 0px;
}

@media screen and (min-width: 420px) {
    .menu {
      width: 300px;
      left: -320px;
    }
  }
  