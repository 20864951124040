.button{
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: none;
    padding: 7px 25px;
    font-size: 16px;
    border-radius: 5px;
    margin: auto;
    cursor: pointer;
    font-weight: 700;
}