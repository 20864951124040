.number_inputs{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-bottom: 30px;
}

.submit_button{
    margin-top: 20px;
    margin-bottom: 50px;
}

@media screen and (min-width: 1024px) {
    .number_inputs{
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
    }
  }

.gif{
    width: 200px;
    height: 200px;
    margin: 10px auto 0;
    display: block;
}