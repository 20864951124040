.main{
    width:100%;
    height: 80px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.title{
    margin:0 auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    cursor:default;
}