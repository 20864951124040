.main {
  text-align: center;
  margin: 30px auto;
  width: 250px;
  height: 120px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.title {
  margin-bottom: 5px;
}

.note {
  margin-top: 0px;
  font-weight: bold;
  font-size: 13px;
}

.input_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  border-radius: 5px;
  height: 30px;
  width: 125px;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin: auto;
}

.input_container button {
  border: none;
  background-color: inherit;
  font-size: 20px;
  cursor: pointer;
}

.numberInput::-webkit-inner-spin-button,
.numberInput::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.numberInput {
  width: 50px;
  border: 0;
  font-size: 18px;
  font-weight: 700;
  background-color: inherit;
  z-index: 1;
  -webkit-box-shadow: 0 3px 6px rgba(rgb(212, 212, 212), 0.25);
  box-shadow: 0 3px 6px rgba(rgb(212, 212, 212), 0.25);
  -moz-appearance: textfield;
  text-align: center;
}

.numberInput:focus {
  outline: 0;
  -webkit-box-shadow: 0px;
  box-shadow: 0px;
}

.description {
  height: 80px;
}

@media screen and (min-width: 1024px) {
  .main {
    text-align: center;
    margin: 30px 20px;
    width: 250px;
  }
}
