.main_container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
}

.title{
    margin: 0px;
}

.color_input{
    -webkit-appearance: none;
    background-color: inherit;
    border: 2px solid white ;
    width: 20px;
    height: 20px;
    padding: 0px ;
    border-radius: 100px;
    overflow: hidden;
    position: relative;
    margin-left: 10px;
}

.color_input::-webkit-color-swatch-wrapper {
	padding: 0;
    margin: 0px;
    width: 120%;
    height: 120%;
    background-color: inherit;
    border-radius: 100px;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
}

