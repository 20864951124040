.svg_container{
    height: 30px;
    width: 30px;
    position: absolute;
    top: 24px;
    left: 15px;
    -webkit-transition: all .6s ease;
    -o-transition: all .6s ease;
    transition: all .6s ease;
    cursor: pointer;
    background-color: inherit;
    border: none;
    padding: 0;
}

.svg_container.opened{
    -webkit-transform: rotate(120deg);
        -ms-transform: rotate(120deg);
            transform: rotate(120deg);
}

