.select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  outline: 0;
  border: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding: 3px;
  background-image: none;
  cursor: pointer;
  background-color: inherit;
  width: 130px;
  height: 25px;
  color: inherit;
  border-radius: 3px;
}

.select::-ms-expand {
  display: none;
}

.custom_wrapper {
  position: relative;
  height: 25px;
  border-radius: 20px;
  overflow: hidden;
}

.custom_wrapper::after {
    content: '\25BC';
    position: absolute;
    right: 0;
    padding: 2px 8px;
    color: inherit;
    pointer-events: none;
  }