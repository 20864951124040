.title{
    text-align: center;
    font-size: 25px;
}

.finished_focuses{
    text-align: right;
    font-size: 20px;
    margin-right: 2rem;
}
