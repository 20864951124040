.timer_container {
  margin-bottom: 50px;
}

.progress_circle {
  position: relative;
  margin: 20px auto;
  width: 200px;
  height: 200px;
  background-color: #d9d9d9;
  border-radius: 50%;
}

.progress_circle__slice {
  width: 200px;
  height: 200px;
  position: absolute;
  -webkit-transition: -webkit-transform .6s;
  transition: -webkit-transform .6s;
  -o-transition: transform .6s;
  transition: transform .6s;
  transition: transform .6s, -webkit-transform .6s;
  border-radius: 50%;
  clip: rect(0px, 200px, 200px, 100px);
}

.progress_circle__fill {
  width: 200px;
  height: 200px;
  position: absolute;
  -webkit-transition: -webkit-transform .6s;
  transition: -webkit-transform .6s;
  -o-transition: transform .6s;
  transition: transform .6s;
  transition: transform .6s, -webkit-transform .6s;
  border-radius: 50%;
  clip: rect(0px, 100px, 200px, 0px);
}

.progress_circle__overlay {
  width: 170px;
  height: 170px;
  position: absolute;
  margin-left: 15px;
  margin-top: 15px;
  background-color: #ffffff;
  border-radius: 50%;
}

.progress_circle__value {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1;
  font-size: 2.5rem;
}
